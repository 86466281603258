











































import { Component, Vue } from "vue-property-decorator";
//import * as Model from "@gigalot/data-models";
//const lodash = require("lodash");

import {
  DimssaButton,
  ButtonState
} from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton
  }
})
export default class MixResults extends Vue {
  name = "mixResults";
  loading = false;

   headers = [
    {
      text: "Ration",
      value: "RationCode",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Tractor",
      value: "TractorID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
      group: false
    },
    {
      text: "Load Duration",
      value: "LoadDuration",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Amount (kg)",
      value: "ActualWeight",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    }
  ];

  mixResults = [];
  urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};
  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

  variables = {};
  operationName = null;

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.getMixResults);
   }

  async getMixResults() {
    this.loading = true;
    try {
      console.log("getMixingResults()");

      let gql = `query($guid:String!){
          MixResults(guid:$guid){
           guid
           MixID
           RationCode
           MixStartTime
           MixEndTime
           RationWeightRequired
           ActualWeight
           TractorID
           LoadDuration
           LoadIngredients {
              MaterialCode
              ActualWeight
              MixSequenceNumber
              FelID
              LoadStartTime
              LoadEndTime
              OperatorID
           }
        }
      }`;
      let json = await this.$store.dispatch("graphQl", { gql, variables:{guid:this.$store.state.user.location.guid }, url: "https://pi.gigalot.systems:7777/feeder" });
      console.log(`Done Downloading Mix Results: ${json.data.MixResults.length}`);
      this.mixResults = json.data.MixResults;
      this.loading = false;
    } catch (err) {
      console.log("mixResults -> error: " + err);
    }
  }
}
